export function validEmail(val) {
  //eslint-disable-next-line
  let regEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/;
  return regEmail.test(val);
}

export function validPassword(val) {
  let validated = true;

  if (val.length < 12) validated = false;

  if (val.length > 32) validated = false;

  if (!/\d/.test(val)) validated = false;

  if (!/^\S*$/.test(val)) validated = false;

  if (!/[a-z]/.test(val)) validated = false;

  if (!/[A-Z]/.test(val)) validated = false;

  if (!/[0-9]/.test(val)) validated = false;

  //eslint-disable-next-line
  if (!/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(val)) validated = false;

  return validated;
}
