import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alertActions, userActions } from "../_actions";
import { validEmail, validPassword } from "../_helpers/validate";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";

class RegisterPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.clearAlerts();

    this.state = {
      user: {
        firstName: "",
        lastName: "",
        email: "",
        password: "",
        acceptRules: false,
        isFirstNameValid: true,
        isLastNameValid: true,
        isEmailValid: true,
        isPasswordValid: true,
        isAcceptRules: true,
      },
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAcceptRules = this.handleAcceptRules.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value.trim(),
        isFirstNameValid: true,
        isLastNameValid: true,
        isEmailValid: true,
        isPasswordValid: true,
        isAcceptRules: true,
      },
    });

    this.props.clearAlerts();
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        isFirstNameValid: user.firstName,
        isLastNameValid: user.lastName,
        isEmailValid: validEmail(user.email),
        isPasswordValid: validPassword(user.password),
        isAcceptRules: user.acceptRules,
      },
    });

    if (user.firstName && user.lastName && validEmail(user.email) && validPassword(user.password) && user.acceptRules) {
      this.props.register(user);
      this.setState({
        user: {
          ...user,
          password: "",
        },
      });
    }
  }

  handleAcceptRules(event) {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        isFirstNameValid: true,
        isLastNameValid: true,
        isEmailValid: true,
        isPasswordValid: true,
        acceptRules: event.target.checked,
        isAcceptRules: true,
      },
    });

    this.props.clearAlerts();
  }

  handleDownload(event) {
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/api/resource/regulamin`, {
        responseType: "arraybuffer",
      })
      .then(function (response) {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "regulaminIGA.pdf";
        link.click();
      });
  }

  render() {
    const { user } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <Box border={2} borderColor="primary.main" padding={5} minWidth={400}>
          <Container maxWidth="xs">
            <h2>Utwórz konto IGA</h2>
            <div>
              <TextField
                label="Imię"
                name="firstName"
                fullWidth
                value={user.firstName}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                autoComplete="off"
                inputProps={{ maxLength: 50 }}
                error={!user.isFirstNameValid}
                helperText={!user.isFirstNameValid && "Imię jest wymagane"}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <TextField
                label="Nazwisko"
                name="lastName"
                fullWidth
                value={user.lastName}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                autoComplete="off"
                inputProps={{ maxLength: 100 }}
                error={!user.isLastNameValid}
                helperText={!user.isLastNameValid && "Nazwisko jest wymagane"}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <TextField
                label="Email"
                name="email"
                fullWidth
                value={user.email}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                autoComplete="off"
                inputProps={{ maxLength: 320 }}
                error={!user.isEmailValid}
                helperText={!user.isEmailValid && "Proszę podać prawidłowy email"}
                InputLabelProps={{ shrink: true }}
              />
            </div>
            <div>
              <TextField
                label="Hasło"
                name="password"
                type="password"
                fullWidth
                value={user.password}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                autoComplete="off"
                inputProps={{ maxLength: 32 }}
                error={!user.isPasswordValid}
                helperText={
                  !user.isPasswordValid && "Hasło nie spełnia wymagań (min 12 znaków, jedna cyfra, jedna mała i duża litera, jeden znak specjalny)"
                }
                InputLabelProps={{ shrink: true }}
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <a href="#" onClick={this.handleDownload}>
                Regulamin korzystania z Systemu IGA
              </a>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <FormControl required error={!user.isAcceptRules} component="fieldset" variant="outlined">
                <FormControlLabel
                  control={<Checkbox checked={user.acceptRules} onChange={this.handleAcceptRules} name="accept" autoComplete="off" margin="normal" />}
                  label="Akceptuję regulamin"
                />
                <FormHelperText>{!user.isAcceptRules && "Proszę zaakceptować regulamin"}</FormHelperText>
              </FormControl>
            </div>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <div>
              <Button onClick={this.handleSubmit} variant="contained">
                Utwórz konto
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button variant="contained">Anuluj</Button>
              </Link>
            </div>
          </Container>
        </Box>
      </div>
    );
  }
}

function mapState(state) {
  const { registering } = state.registration;
  return { registering };
}

const actionCreators = {
  register: userActions.register,
  clearAlerts: alertActions.clear,
};

const connectedRegisterPage = connect(mapState, actionCreators)(RegisterPage);
export { connectedRegisterPage as RegisterPage };
