import { combineReducers } from "redux";

import { authentication } from "./authentication.reducer";
import { registration } from "./registration.reducer";
import { changePassword } from "./changePassword.reducer";
import { resetPassword } from "./resetPassword.reducer";
import { activateAccount } from "./activateAccount.reducer";
import { submitTermsOfService } from "./submitTermsOfService.reducer";
import { alert } from "./alert.reducer";

const rootReducer = combineReducers({
  authentication,
  registration,
  changePassword,
  resetPassword,
  alert,
  activateAccount,
  submitTermsOfService,
});

export default rootReducer;
