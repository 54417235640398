import React from "react";

export const IGAFooter = () => {
  var style = {
    backgroundColor: "#F8F8F8",
    borderTop: "1px solid #E7E7E7",
    textAlign: "center",
    padding: "20px",
    position: "fixed",
    left: "0",
    bottom: "0",
    width: "100%",
  };

  return (
    <div>
      <div style={style}>
        <img src="images/logo.png" alt="Logo systemu IGA" style={{ maxWidth: "1000px", height: "100%" }} />
      </div>
    </div>
  );
};
