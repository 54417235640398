import { userConstants } from "../_constants";

export function changePassword(state = {}, action) {
  switch (action.type) {
    case userConstants.CHANGE_PASS_REQUEST:
      return { changingPass: true };
    case userConstants.CHANGE_PASS_SUCCESS:
      return {};
    case userConstants.CHANGE_PASS_FAILURE:
      return {};
    default:
      return state;
  }
}
