import { userConstants } from "../_constants";
import { userService } from "../_services";
import { alertActions } from "./";

export const userActions = {
  login,
  logout,
  register,
  changePass,
  resetPass,
  activateAccount,
  submitTermsOfService,
};

function login(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.login(user.email, user.password).then(
      (user) => {
        dispatch(success(user));

        if (user.activeRegulationId !== null && user.activeRegulationId !== undefined && user.activeRegulationId !== "") {
          window.location.href = process.env.REACT_APP_LOGIN_URL + "/termsofservice";
        } else {
          window.location.href = user.redirectUrl;
        }
      },
      (error) => {
        dispatch(failure(error.toString()));

        if (error.toString().includes("Password expired")) {
          var token = error.toString().substr(0, error.toString().indexOf("Password"));
          dispatch(alertActions.error("Hasło wygasło!"));
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_LOGIN_URL + "/changepass/" + token;
          }, 5000);
        } else if (error.toString().includes("Unauthorized")) {
          dispatch(alertActions.error("Konto nie istnieje bądź błędne dane"));
        } else if (error.toString().includes("Error")) {
          dispatch(alertActions.error("Błąd wewnętrzny. Skontaktuj się z administratorem systemu IGA"));
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.LOGIN_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.LOGIN_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.LOGIN_FAILURE, error };
  }
}

function logout() {
  userService.logout();
  return { type: userConstants.LOGOUT };
}

function register(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.register(user).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success("Utworzono konto. Na podany adres email został wysłany link z aktywacją konta"));
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 5000);
      },
      (error) => {
        dispatch(failure(error.toString()));

        if (error.toString().includes("Error")) {
          dispatch(alertActions.error("Błąd wewnętrzny. Skontaktuj się z administratorem systemu LSI"));
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.REGISTER_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.REGISTER_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.REGISTER_FAILURE, error };
  }
}

function changePass(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.changePass(user).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success("Hasło zostało zmienione"));
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 5000);
      },
      (error) => {
        dispatch(failure(error.toString()));
        if (error.toString().includes("Error")) {
          dispatch(alertActions.error("Błąd wewnętrzny. Skontaktuj się z administratorem systemu IGA"));
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.CHANGE_PASS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.CHANGE_PASS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.CHANGE_PASS_FAILURE, error };
  }
}

function resetPass(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.resetPass(user).then(
      (user) => {
        dispatch(success());
        dispatch(alertActions.success("Link z możliwością zmiany hasła został wysłany na podany adres email"));
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 5000);
      },
      (error) => {
        dispatch(failure(error.toString()));
        if (error.toString().includes("Error")) {
          dispatch(alertActions.error("Błąd wewnętrzny. Skontaktuj się z administratorem systemu IGA"));
        } else {
          dispatch(alertActions.error(error.toString()));
          setTimeout(() => {
            window.location.href = process.env.REACT_APP_LOGIN_URL;
          }, 5000);
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.RESET_PASS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.RESET_PASS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.RESET_PASS_FAILURE, error };
  }
}

function activateAccount(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.activateAccount(user).then(
      () => {
        dispatch(success());
        dispatch(alertActions.success("Konto aktywowane!"));
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 5000);
      },
      (error) => {
        dispatch(failure(error.toString()));
        if (error.toString().includes("Error")) {
          dispatch(alertActions.error("Błąd wewnętrzny. Skontaktuj się z administratorem systemu IGA"));
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.ACTIVATE_ACCOUNT_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.ACTIVATE_ACCOUNT_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.ACTIVATE_ACCOUNT_FAILURE, error };
  }
}

function submitTermsOfService(user) {
  return (dispatch) => {
    dispatch(request(user));

    userService.submitTermsOfService(user).then(
      () => {
        dispatch(success());
        dispatch(alertActions.success("Regulamin zaakceptowany!"));
        setTimeout(() => {
          window.location.href = process.env.REACT_APP_LOGIN_URL;
        }, 5000);
      },
      (error) => {
        dispatch(failure(error.toString()));
        if (error.toString().includes("Error")) {
          dispatch(alertActions.error("Błąd wewnętrzny. Skontaktuj się z administratorem systemu IGA"));
        } else {
          dispatch(alertActions.error(error.toString()));
        }
      }
    );
  };

  function request(user) {
    return { type: userConstants.SUBMIT_TOS_REQUEST, user };
  }
  function success(user) {
    return { type: userConstants.SUBMIT_TOS_SUCCESS, user };
  }
  function failure(error) {
    return { type: userConstants.SUBMIT_TOS_FAILURE, error };
  }
}
