import { userConstants } from "../_constants";

export function resetPassword(state = {}, action) {
  switch (action.type) {
    case userConstants.RESET_PASS_REQUEST:
      return { changingPass: true };
    case userConstants.RESET_PASS_SUCCESS:
      return {};
    case userConstants.RESET_PASS_FAILURE:
      return {};
    default:
      return state;
  }
}
