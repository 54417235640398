import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alertActions, userActions } from "../_actions";
import { validEmail } from "../_helpers/validate";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";

class ResetPassPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.clearAlerts();

    this.state = {
      user: {
        email: "",
        isEmailValid: true,
      },
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
        isEmailValid: true,
      },
    });

    this.props.clearAlerts();
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        isEmailValid: validEmail(user.email),
      },
    });

    if (validEmail(user.email)) {
      this.props.resetPass(user);
      this.setState({
        user: {
          email: "",
          isEmailValid: true,
        },
      });
    }
  }

  render() {
    const { user } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <Box border={2} borderColor="primary.main" padding={5} minWidth={400}>
          <Container maxWidth="xs">
            <h2>Resetowanie hasła w systemie IGA</h2>
            <div>
              <TextField
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                fullWidth
                value={user.email}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 320 }}
                error={!user.isEmailValid}
                helperText={!user.isEmailValid && "Proszę podać prawidłowy email"}
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <Button onClick={this.handleSubmit} variant="contained">
                Resetuj hasło
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button variant="contained">Anuluj</Button>
              </Link>
            </div>
          </Container>
        </Box>
      </div>
    );
  }
}

function mapState(state) {
  const { resetingPass } = state.resetPassword;
  return { resetingPass };
}

const actionCreators = {
  resetPass: userActions.resetPass,
  clearAlerts: alertActions.clear,
};

const connectedResetPassPage = connect(mapState, actionCreators)(ResetPassPage);
export { connectedResetPassPage as ResetPassPage };
