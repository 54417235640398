export const userConstants = {
  REGISTER_REQUEST: "USERS_REGISTER_REQUEST",
  REGISTER_SUCCESS: "USERS_REGISTER_SUCCESS",
  REGISTER_FAILURE: "USERS_REGISTER_FAILURE",

  LOGIN_REQUEST: "USERS_LOGIN_REQUEST",
  LOGIN_SUCCESS: "USERS_LOGIN_SUCCESS",
  LOGIN_FAILURE: "USERS_LOGIN_FAILURE",

  LOGOUT: "USERS_LOGOUT",

  CHANGE_PASS_REQUEST: "CHANGE_PASS_REQUEST",
  CHANGE_PASS_SUCCESS: "CHANGE_PASS_SUCCESS",
  CHANGE_PASS_FAILURE: "CHANGE_PASS_FAILURE",

  RESET_PASS_REQUEST: "RESET_PASS_REQUEST",
  RESET_PASS_SUCCESS: "RESET_PASS_SUCCESS",
  RESET_PASS_FAILURE: "RESET_PASS_FAILURE",

  ACTIVATE_ACCOUNT_REQUEST: "ACTIVATE_ACCOUNT_REQUEST",
  ACTIVATE_ACCOUNT_SUCCESS: "ACTIVATE_ACCOUNT_SUCCESS",
  ACTIVATE_ACCOUNT_FAILURE: "ACTIVATE_ACCOUNT_FAILURE",

  SUBMIT_TOS_REQUEST: "SUBMIT_TOS_REQUEST",
  SUBMIT_TOS_SUCCESS: "SUBMIT_TOS_SUCCESS",
  SUBMIT_TOS_FAILURE: "SUBMIT_TOS_FAILURE",
};
