import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alertActions, userActions } from "../_actions";
import { validEmail, validPassword } from "../_helpers/validate";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import axios from "axios";
import { getUserCookie } from "../_helpers/cookies";

class TermsOfServicePage extends React.Component {
  constructor(props) {
    super(props);

    this.props.clearAlerts();

    this.state = {
      user: {
        acceptRules: false,
        isAcceptRules: true,
      },
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAcceptRules = this.handleAcceptRules.bind(this);
    this.handleDownload = this.handleDownload.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value.trim(),
        isAcceptRules: true,
      },
    });

    this.props.clearAlerts();
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { user } = this.state;

    let tempUser = getUserCookie();

    if (user.acceptRules) {
      let submitTos = {
        userId: tempUser.id,
        activeRegulationId: tempUser.activeRegulationId,
        refreshToken: tempUser.refreshToken,
      };

      axios.post(`${process.env.REACT_APP_AUTH_API_URL}/api/termsofservice/submit/`, submitTos).then(function () {
        window.location.href = tempUser.redirectUrl;
      });
    }

    this.setState({
      user: {
        ...user,
        isAcceptRules: user.acceptRules,
      },
    });
  }

  handleAcceptRules(event) {
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        acceptRules: event.target.checked,
        isAcceptRules: true,
      },
    });

    this.props.clearAlerts();
  }

  handleDownload(event) {
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/api/resource/regulamin`, {
        responseType: "arraybuffer",
      })
      .then(function (response) {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "regulaminIGA.pdf";
        link.click();
      });
  }

  render() {
    const { user } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <Box border={2} borderColor="primary.main" padding={5} minWidth={400}>
          <Container maxWidth="ms">
            <h2>Aktualizacja regulaminu Systemu IGA</h2>
            &nbsp;&nbsp;&nbsp;
            <div>
              <a href="#" onClick={this.handleDownload}>
                Pobierz regulamin
              </a>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <FormControl required error={!user.isAcceptRules} component="fieldset" variant="outlined">
                <FormControlLabel
                  control={<Checkbox checked={user.acceptRules} onChange={this.handleAcceptRules} name="accept" autoComplete="off" margin="normal" />}
                  label="Akceptuję regulamin"
                />
                <FormHelperText>{!user.isAcceptRules && "Proszę zaakceptować regulamin"}</FormHelperText>
              </FormControl>
            </div>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <div>
              <Button onClick={this.handleSubmit} variant="contained">
                Akceptuję regulamin
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button variant="contained">Anuluj</Button>
              </Link>
            </div>
          </Container>
        </Box>
      </div>
    );
  }
}

function mapState(state) {
  const { submitTermsOfService } = state.submitTermsOfService;
  return { submitTermsOfService };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedTermsOfServicePage = connect(mapState, actionCreators)(TermsOfServicePage);
export { connectedTermsOfServicePage as TermsOfServicePage };
