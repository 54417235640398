import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alertActions, userActions } from "../_actions";
import { validPassword } from "../_helpers/validate";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";

class ChangePassPage extends React.Component {
  constructor(props) {
    super(props);

    this.props.clearAlerts();

    this.state = {
      user: {
        newPassword: "",
        newConfirmPassword: "",
        isNewPasswordValid: true,
        isNewConfirmPasswordValid: true,
        token: this.props.urlParams.token,
      },
      submitted: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    const { name, value } = event.target;
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
        isNewPasswordValid: true,
        isNewConfirmPasswordValid: true,
      },
    });

    this.props.clearAlerts();
  }

  handleSubmit(event) {
    event.preventDefault();

    this.setState({ submitted: true });
    const { user } = this.state;

    this.setState({
      user: {
        ...user,
        isNewPasswordValid: validPassword(user.newPassword),
        isNewConfirmPasswordValid: validPassword(user.newConfirmPassword) && user.newPassword === user.newConfirmPassword,
      },
    });

    if (validPassword(user.newPassword) && validPassword(user.newConfirmPassword) && user.newPassword === user.newConfirmPassword) {
      this.props.changePass(user);
    }
  }

  render() {
    const { user } = this.state;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <Box id="main-changepass-box" border={2} borderColor="primary.main" padding={5} minWidth={400}>
          <Container id="main-changepass-container" maxWidth="xs">
            <h2>Zmień hasło w systemie IGA</h2>
            <div>
              <TextField
                id="newpass-text"
                label="Nowe hasło"
                name="newPassword"
                type="password"
                fullWidth
                value={user.newPassword}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 32 }}
                error={!user.isNewPasswordValid}
                helperText={
                  !user.isNewPasswordValid && "Hasło nie spełnia wymagań (min 12 znaków, jedna cyfra, jedna mała i duża litera, jeden znak specjalny)"
                }
              />
            </div>
            <div>
              <TextField
                id="confirm-newpass-text"
                label="Potwierdź nowe hasło"
                name="newConfirmPassword"
                type="password"
                fullWidth
                value={user.newConfirmPassword}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 32 }}
                error={!user.isNewConfirmPasswordValid}
                helperText={!user.isNewConfirmPasswordValid && "Błędnie potwierdzone hasło"}
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <Button id="changepass-button" onClick={this.handleSubmit} variant="contained">
                Zmień hasło
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Link id="login-link" to="/login" style={{ textDecoration: "none" }}>
                <Button id="cancel-button" variant="contained">
                  Anuluj
                </Button>
              </Link>
            </div>
          </Container>
        </Box>
      </div>
    );
  }
}

function mapState(state) {
  const { changingPass } = state.changePassword;
  return { changingPass };
}

const actionCreators = {
  changePass: userActions.changePass,
  clearAlerts: alertActions.clear,
};

const connectedChangePassPage = connect(mapState, actionCreators)(ChangePassPage);
export { connectedChangePassPage as ChangePassPage };
