import { CollectionsBookmark } from "@mui/icons-material";
import CookieService from "encrypted-cookie";

export const userService = {
  login,
  logout,
  register,
  changePass,
  resetPass,
  activateAccount,
};

export function getUserCookie() {
  let user;

  user = {
    token: "",
    type: "Bearer",
    refreshToken: "",
    id: "",
    username: "",
    email: "",
    activeRoleId: "",
    activeRoleName: "",
    isClientRole: true,
    redirectUrl: "",
    activeRegulationId: null,
  };

  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
  }

  var userCookie = CookieService.getCookie("IGAUser", `${process.env.REACT_APP_COOKIE_KEY}`);

  if (userCookie) {
    userCookie = decodeURI(userCookie);
    user = JSON.parse(userCookie);

    var ca = document.cookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
    }
  }

  return user;
}

function login(email, password) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  };

  return fetch(`${process.env.REACT_APP_AUTH_API_URL}/api/auth/signin`, requestOptions)
    .then(handleResponse)
    .then(CollectionsBookmark)
    .then((user) => {
      CookieService.setCookie("IGAUser", encodeURI(JSON.stringify(user)), 1, `${process.env.REACT_APP_COOKIE_KEY}`);
      getUserCookie();
      return user;
    });
}

function logout() {
  CookieService.eraseCookie("IGAUser", `${process.env.REACT_APP_COOKIE_KEY}`);
}

function register(user) {
  var userItem = {
    username: "",
    email: "",
    password: "",
    role: [],
  };

  userItem.username = user.lastName.replace(/\s/g, "") + " " + user.firstName.replace(/\s/g, "");
  userItem.email = user.email;
  userItem.password = user.password;
  userItem.role = ["user"];

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userItem),
  };

  return fetch(`${process.env.REACT_APP_AUTH_API_URL}/api/auth/signup`, requestOptions).then(handleResponse);
}

function changePass(user) {
  var userItem = {
    newPassword: "",
    newConfirmPassword: "",
    token: "",
  };

  userItem.newPassword = user.newPassword;
  userItem.email = user.email;
  userItem.newConfirmPassword = user.newConfirmPassword;
  userItem.token = user.token;

  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${process.env.REACT_APP_AUTH_API_URL}/api/auth/changepass`, requestOptions).then(handleResponse);
}

function resetPass(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(user),
  };

  return fetch(`${process.env.REACT_APP_AUTH_API_URL}/api/auth/resetpass`, requestOptions).then(handleResponse);
}

function activateAccount(user) {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: user.activateToken,
  };

  return fetch(`${process.env.REACT_APP_AUTH_API_URL}/api/auth/activateaccount`, requestOptions).then(handleResponse);
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        //logout();
        //location.reload(true);
      }

      const error = (data && data.message) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}
