import React from "react";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { userActions } from "../_actions";
import { connect } from "react-redux";

class ExpiredsessionPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();
  }

  render() {
    return (
      <div
        style={{
          width: "100%",
          height: "50vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            p: 1,
            m: 1,
            bgcolor: "background.paper",
            borderRadius: 1,
          }}
        >
          <Box sx={{ width: "100%", maxWidth: 700 }}>
            <Box>
              <Typography variant="h5" gutterBottom>
                Zostałeś wylogowany automatycznie z powodu zalogowania na innej przeglądarce/urządzeniu! &nbsp;&nbsp;&nbsp;
              </Typography>
            </Box>
            <Box>
              <Typography variant="h5" gutterBottom>
                <Link to="/login" style={{ textDecoration: "none" }}>
                  zaloguj ponownie
                </Link>
              </Typography>
            </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  logout: userActions.logout,
};

const connectedExpiredsessionPage = connect(mapState, actionCreators)(ExpiredsessionPage);
export { connectedExpiredsessionPage as ExpiredsessionPage };
