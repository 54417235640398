import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import TextField from "@mui/material/TextField";
import { List, ListItem, ListItemText } from "@mui/material/";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alertActions, userActions } from "../_actions";
import { validEmail } from "../_helpers/validate";
import axios from "axios";

class LoginPage extends React.Component {
  constructor(props) {
    super(props);

    // reset login status
    this.props.logout();

    setTimeout(() => {
      this.props.clearAlerts();
    }, 8000);

    this.state = {
      email: "",
      password: "",
      isEmailValid: true,
      isPasswordValid: true,
      submitted: false,
      informations: [],
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleDownload = this.handleDownload.bind(this);

    console.log("this.props.urlParams");
    console.log(this.props.urlParams);

    if (this.props.urlParams && this.props.urlParams.token && this.props.urlParams.token !== "termsofservice") {
      let user = {
        activateToken: this.props.urlParams.token,
      };
      this.props.activateAccount(user);
    }
  }

  componentDidMount() {
    axios.get(`${process.env.REACT_APP_AUTH_API_URL}/api/information/all`).then((res) => {
      const informations = res.data;
      this.setState({ informations });
    });
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ isEmailValid: true });
    this.setState({ isPasswordValid: true });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ submitted: true });
    const { email, password } = this.state;
    let canLogin = true;

    if (!email || !validEmail(email)) {
      this.setState({ isEmailValid: false });
      canLogin = false;
    }

    if (!password) {
      this.setState({ isPasswordValid: false });
      canLogin = false;
    }

    if (canLogin) {
      let user = { email: email, password: password };
      this.props.login(user);
      this.setState({ password: "" });
    }
  }

  handleDownload(event) {
    axios
      .get(`${process.env.REACT_APP_AUTH_API_URL}/api/resource/regulamin`, {
        responseType: "arraybuffer",
      })
      .then(function (response) {
        const type = response.headers["content-type"];
        const blob = new Blob([response.data], { type: type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = "regulaminIGA.pdf";
        link.click();
      });
  }

  render() {
    const { email, password, isEmailValid, isPasswordValid, informations } = this.state;
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          paddingTop: "5%",
        }}
      >
        <Box id="main-login-box" border={2} borderColor="primary.main" padding={5} minWidth={400}>
          <Container id="main-login-container" maxWidth="xs">
            <h2>Logowanie do systemu IGA</h2>
            <div>
              <TextField
                id="email-text"
                label="Email"
                name="email"
                autoComplete="email"
                type="email"
                fullWidth
                value={email}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 100 }}
                error={!isEmailValid}
                helperText={!isEmailValid && "Proszę podać prawidłowy email"}
              />
            </div>
            <div>
              <TextField
                id="pass-text"
                label="Hasło"
                name="password"
                type="password"
                fullWidth
                autoComplete="current-password"
                value={password}
                onChange={this.handleChange}
                variant="outlined"
                margin="normal"
                inputProps={{ maxLength: 32 }}
                error={!isPasswordValid}
                helperText={!isPasswordValid && "Hasło jest wymagane"}
              />
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <Button id="logon-button" onClick={this.handleSubmit} variant="contained">
                Zaloguj
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Link id="register-link" to="/login/register" style={{ textDecoration: "none" }}>
                <Button id="create-account-button" variant="contained">
                  Utwórz konto IGA
                </Button>
              </Link>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <a href="/login/resetpassword">Nie pamiętasz hasła?</a>
            </div>
            &nbsp;&nbsp;&nbsp;
            <div>
              <a href="#" onClick={this.handleDownload}>
                Regulamin korzystania z Systemu IGA
              </a>
            </div>
          </Container>

          {informations.length > 0 && (
            <Box>
              <Box height={30}></Box>
              <Container id="info-container" maxWidth="xs">
                <List dense component="div" role="list">
                  {informations.map((info) => {
                    const labelId = `list-item-${info.id}-label`;

                    return (
                      <Box padding={1}>
                        <ListItem key={info.id} role="listitem" sx={{ border: 3, borderColor: "red", padding: 2 }}>
                          <ListItemText id={labelId} primary={info.description} />
                        </ListItem>
                      </Box>
                    );
                  })}
                </List>
              </Container>
            </Box>
          )}
        </Box>
      </div>
    );
  }
}

function mapState(state) {
  const { loggingIn } = state.authentication;
  return { loggingIn };
}

const actionCreators = {
  login: userActions.login,
  logout: userActions.logout,
  clearAlerts: alertActions.clear,
  activateAccount: userActions.activateAccount,
};

const connectedLoginPage = connect(mapState, actionCreators)(LoginPage);
export { connectedLoginPage as LoginPage };
