import React from "react";
import { BrowserRouter, Route, Routes, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { history } from "../_helpers";
import { alertActions } from "../_actions";
import { LoginPage } from "../LoginPage";
import { RegisterPage } from "../RegisterPage";
import { ChangePassPage } from "../ChangePassPage";
import { ResetPassPage } from "../ResetPassPage";
import { ExpiredsessionPage } from "../ExpiredsessionPage";
import { TermsOfServicePage } from "../TermsOfServicePage";
import { IGAHeader } from "../_components/IGAHeader";
import { IGAFooter } from "../_components/IGAFooter";
import Stack from "@mui/material/Stack";
import MuiAlert from "@mui/material/Alert";
import { getUserCookie } from "../_helpers/cookies";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const WrappedComponentChangePass = (props) => {
  const urlParams = useParams();

  return <ChangePassPage urlParams={urlParams} {...props} />;
};

export const WrappedComponentLoginPage = (props) => {
  const urlParams = useParams();

  return <LoginPage urlParams={urlParams} {...props} />;
};

export const WrappedComponentExpiredLoginPage = (props) => {
  const urlParams = useParams();

  return <LoginPage urlParams={urlParams} {...props} />;
};

class App extends React.Component {
  constructor(props) {
    super(props);

    history.listen((location, action) => {
      // clear alert on location change

      setTimeout(() => {
        this.props.clearAlerts();
      }, 5000);
    });
  }

  render() {
    const { alert } = this.props;

    return (
      <div role="main" aria-labelledby="Logowanie do systemu IGA">
        <IGAHeader />

        {process.env.REACT_APP_TEST_MODE == 1 && (
          <Box display="flex" justifyContent="center" marginTop={10}>
            <Typography variant="h4" display="block" gutterBottom color="orange" style={{ whiteSpace: "pre-wrap" }}>
              WERSJA TESTOWA
            </Typography>
          </Box>
        )}
        <div>
          <div>
            <BrowserRouter history={history}>
              <Routes>
                <Route index element={<LoginPage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/login/:token" element={<WrappedComponentLoginPage />} />
                <Route path="/login/register" element={<RegisterPage />} />
                <Route path="/login/changepass" element={<LoginPage />} />
                <Route path="/login/changepass/:token" element={<WrappedComponentChangePass />} />
                <Route path="/login/resetpassword" element={<ResetPassPage />} />
                <Route path="/login/expiredsession" element={<ExpiredsessionPage />} />
                <Route path="/login/termsofservice" element={<TermsOfServicePage />} />

                <Route path="/login/:token" element={<WrappedComponentLoginPage />} />
                <Route path="/register" element={<RegisterPage />} />
                <Route path="/changepass" element={<LoginPage />} />
                <Route path="/changepass/:token" element={<WrappedComponentChangePass />} />
                <Route path="/resetpassword" element={<ResetPassPage />} />
                <Route path="/termsofservice" element={<TermsOfServicePage />} />
                <Route exact path="/*" element={<LoginPage />} />
                <Route exact path="/" element={<LoginPage />} />
              </Routes>
            </BrowserRouter>
            {alert.message && (
              <Stack
                spacing={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                  minWidth: "500px",
                  paddingTop: "20px",
                }}
              >
                <Alert
                  severity="error"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    minWidth: "500px",
                  }}
                >
                  {alert.message}
                </Alert>
              </Stack>
            )}
          </div>
        </div>
        <Box paddingBottom={30}></Box>
        {/* <IGAFooter /> */}
      </div>
    );
  }
}

function mapState(state) {
  const { alert } = state;
  return { alert };
}

const actionCreators = {
  clearAlerts: alertActions.clear,
};

const connectedApp = connect(mapState, actionCreators)(App);
export { connectedApp as App };
