import CookieService from "encrypted-cookie";

export function getUserCookie() {
  let user;

  var userCookie = CookieService.getCookie("IGAUser", `${process.env.REACT_APP_COOKIE_KEY}`);
  if (userCookie) {
    userCookie = decodeURI(userCookie);
    user = JSON.parse(userCookie);
  }

  return user;
}
